import useIsBombayClubTheme from '~/client/layout/GamesLayout/hooks/useIsBombayClubTheme';
import DarkThemeProvider from '~/client/shared/ThemeProviders/DarkThemeProvider';

type Props = {
  hasBombayClubTheme?: boolean;
};

const BrandThemeProvider: FC<Props> = ({ children, hasBombayClubTheme }) => {
  const { isBombayClubTheme } = useIsBombayClubTheme();
  if (isBombayClubTheme && hasBombayClubTheme) {
    return <DarkThemeProvider>{children}</DarkThemeProvider>;
  }
  return <>{children}</>;
};

export default BrandThemeProvider;

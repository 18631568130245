import useQueryParam from '@/client/hooks/router/useQueryParam';
import isExternalUrl from '@/shared/utils/isExternalUrl';

const regex = /[^a-z0-9\]\[\\\-_/]/gi;

const useRedirectUrl = () => {
  const [redirect = '/'] = useQueryParam('redirect');
  if (isExternalUrl(redirect)) {
    return '/';
  }
  const sanitizedRedirect = redirect.replaceAll(regex, '');
  return sanitizedRedirect ? sanitizedRedirect : '/';
};

export default useRedirectUrl;

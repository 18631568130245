const setDataToGib = (userId: string, sessionId: string) => {
  if (!window.gib) {
    return;
  }

  window.gib.setAuthStatus(window.gib.IS_AUTHORIZED);
  window.gib.setIdentity(userId);
  window.gib.setSessionID(sessionId);
};

export default setDataToGib;

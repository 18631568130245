/* eslint-disable immutable/no-mutation */
const FRAUD_ENGINE_IFRAME_ID = 'fraudFrame';

type Args = { deviceDataToken?: string };

const startFraudEngine = async ({ deviceDataToken }: Args) => {
  try {
    deleteExistingIframe();
    const fraudIframe = createFraudIframe({ deviceDataToken });
    document.body.appendChild(fraudIframe);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Error starting fraud engine', error);
  }
};

const createFraudIframe = ({ deviceDataToken }: Args) => {
  const fraudIframe = document.createElement('iframe');
  fraudIframe.src = `/public-casino/fraud/fraud.html?deviceDataToken=${deviceDataToken}`;
  fraudIframe.id = FRAUD_ENGINE_IFRAME_ID;
  fraudIframe.setAttribute('style', 'display:none');
  return fraudIframe;
};

const deleteExistingIframe = () => {
  const fraudEngineElement = document.getElementById(FRAUD_ENGINE_IFRAME_ID);
  if (fraudEngineElement) {
    document.body.removeChild(fraudEngineElement);
  }
};

export default startFraudEngine;

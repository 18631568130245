import { useCallback } from 'react';
import useRouter from '~/client/hooks/router/useRouter';
import Cookie from '~/shared/utils/Cookie';

const socialAuthLocaleKey = 'social_auth_locale';

const useSocialAuthLocale = () => {
  const { locale: routerLocale } = useRouter();

  const get = useCallback(() => Cookie.get(socialAuthLocaleKey) as string, []);

  const set = useCallback(
    (socialAuthLocale = routerLocale) => {
      Cookie.set(socialAuthLocaleKey, socialAuthLocale, {
        expires: inFiveMinutes(),
      });
    },
    [routerLocale],
  );

  const clear = useCallback(() => {
    Cookie.remove(socialAuthLocaleKey);
  }, []);

  const inFiveMinutes = () => new Date(Date.now() + 300000);

  return [get, set, clear] as const;
};

export default useSocialAuthLocale;

import { createContext } from 'react';
import type { GameSessionFragment } from '~/client/components/Game/graphql/GameSessionFragment.generated';
import type { SingleGameFragment } from '~/client/components/Game/graphql/SingleGameFragment.generated';
import type StartSessionProps from './GameContextProvider/useGameContextProvider/useGameSessionProps/StartSessionProps';
import type { GameDescriptionFragment } from '../../graphql/GameDescriptionFragment.generated';

type Props = {
  game?: SingleGameFragment;
  description?: GameDescriptionFragment;
  isFunMode: boolean;
  setFunMode: SetState<boolean>;
  isFullScreen: boolean;
  setFullScreen: SetState<boolean>;
  inGameCurrency?: string | null;
  setInGameCurrency: SetState<Maybe<string | null>>;
  inGameCurrencyList?: string[];
  gameSession?: GameSessionFragment;
  startSession: (props: StartSessionProps) => unknown;
  closeGameSession: () => unknown;
  loadingSession: boolean;
};

const GameContext = createContext<Props>({
  isFunMode: false,
  setFunMode: (_) => null,
  isFullScreen: false,
  setFullScreen: () => undefined,
  setInGameCurrency: (_) => null,
  startSession: (_) => null,
  closeGameSession: () => undefined,
  loadingSession: false,
});

export default GameContext;

import useIsLoggedIn from '~/client/hooks/user/useIsLoggedIn';
import { useHasGroupTagQuery } from '~/shared/graphql/hasGroupTags/HasGroupTagQuery.generated';

const useHasGroupTags = (): boolean | undefined => {
  const isLoggedIn = useIsLoggedIn();

  const { data, loading } = useHasGroupTagQuery({ skip: !isLoggedIn });
  return loading ? undefined : Boolean(data?.features?.isVip);
};

export default useHasGroupTags;

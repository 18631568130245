import isExternalHref from '~/shared/utils/router/isExternalHref';
import nativeRedirect from '~/shared/utils/router/nativeRedirect';
import useRouter from './useRouter';

const useRedirect = () => {
  const router = useRouter();
  const redirect = (href: string, operation: 'push' | 'replace' = 'push') => {
    if (isExternalHref(href)) {
      nativeRedirect(href);
      return;
    }
    router[operation](href);
  };
  return { redirect };
};
export default useRedirect;

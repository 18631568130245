import {
  TextInput as BaseTextInput,
  TextInputProps,
} from '@heathmont/moon-core';
import { rem } from '@heathmont/moon-utils';
import styled from 'styled-components';

const TextInput = styled(BaseTextInput)<TextInputProps>`
  &:hover,
  &:focus {
    border-radius: ${({ isSharpTopSide, isSharpBottomSide }) => {
      if (isSharpTopSide) {
        return `0 0 ${rem(12)} ${rem(12)} !important`;
      }
      if (isSharpBottomSide) {
        return `${rem(12)} ${rem(12)} 0 0 !important`;
      }
      return `${rem(12)} !important`;
    }};
  }
`;

export default TextInput;

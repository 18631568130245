import type { TextInputProps } from '@heathmont/moon-core';

const sizes: Record<string, 'md' | 'lg'> = {
  medium: 'md',
  large: 'lg',
};

const useInputProps = (props: TextInputProps) => {
  const {
    isSharpTopSide,
    isSharpBottomSide,
    inputSize,
    size,
    label,
    name,
    isError,
    ...inputProps
  } = props;
  const newInputSize = (inputSize && sizes[inputSize]) || 'md';
  const isOldInput = isSharpTopSide || isSharpBottomSide;
  return { newInputSize, isOldInput, size, label, name, isError, inputProps };
};

export default useInputProps;

/* eslint-disable immutable/no-mutation */
import isSsr from '~/shared/utils/isSsr';
import isExternalHref from './isExternalHref';

const nativeRedirect = (
  href: string,
  locale?: string,
  defaultLocale?: string,
) => {
  if (isSsr()) {
    return;
  }
  if (isExternalHref(href)) {
    location.href = href;
    return;
  }
  if (locale === defaultLocale) {
    location.href = `${location.origin}${href}`;
    return;
  }
  location.href = `${location.origin}/${locale}${href}`;
};

export default nativeRedirect;

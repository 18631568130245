import { Text } from '@heathmont/moon-components';
import Translate from '~/client/shared/i18n/Translate';
import Stack from '~/client/shared/Stack';
import Cookie from '~/shared/utils/Cookie';
import socialLoginErrors from '../../../Shared/utils/socialLoginErrors';

const SocialLoginError = () => {
  const loginErrors = Cookie.get('loginError') as string;
  const errorList = loginErrors?.split(',');
  const loginError = errorList?.find((error) =>
    socialLoginErrors.includes(error),
  );
  if (!loginError) {
    return null;
  }
  const errorMessage = `errors.${loginError}`;
  return (
    <Stack>
      <Text color="piccolo.100" size={12}>
        <Translate id={errorMessage} />
      </Text>
    </Stack>
  );
};

export default SocialLoginError;

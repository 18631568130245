import type { RefObject } from 'react';
import { rem } from '@heathmont/moon-utils';
import Reaptcha from 'reaptcha';
import styled from 'styled-components';
import useSite from '~/client/hooks/useSite';
import LANGUAGE_COOKIE from '~/shared/cookies/LANGUAGE_COOKIE';
import Cookie from '~/shared/utils/Cookie';
import getEnv from '~/shared/utils/getEnv';
import parse from '~/shared/utils/string/parse';

type Props = {
  onVerify?: (token?: string | null) => void;
  recaptchaRef?: RefObject<Reaptcha>;
};

const ReCAPTCHA: FC<Props> = ({ onVerify, recaptchaRef }) => {
  const { site } = useSite();
  const langCode = Cookie.get(LANGUAGE_COOKIE);

  const recaptchaV2ApiKeys = parse(
    getEnv('RECAPTCHA_V2_API_KEY') || '{}',
  ) as Partial<Record<Site, string>>;
  const sitekey = recaptchaV2ApiKeys[site] ?? '';

  const handleLoad = () => {
    if (recaptchaRef?.current) {
      setTimeout(() => {
        if (!recaptchaRef.current?.state.rendered) {
          recaptchaRef.current?.renderExplicitly();
        }
      }, 2000);
    }
  };
  return (
    <Container data-testid="recaptcha-form-error">
      <Reaptcha
        sitekey={sitekey}
        onVerify={onVerify}
        onExpire={onVerify}
        ref={recaptchaRef}
        onLoad={handleLoad}
        hl={langCode}
        explicit
      />
    </Container>
  );
};

const Container = styled.div`
  overflow: hidden;
  max-height: ${rem(300)};
`;

export default ReCAPTCHA;

import { KeylineText } from '@heathmont/moon-components';
import { Text } from '@heathmont/moon-core';
import { rem } from '@heathmont/moon-utils';
import dynamic from 'next/dynamic';
import useDidMount from '~/client/hooks/lifecycle/useDidMount';
import useQueryParam from '~/client/hooks/router/useQueryParam';
import useAbTest from '~/client/hooks/useAbTest';
import useToastContext from '~/client/layout/DefaultLayout/LayoutContext/Toast/useToastContext';
import AuthBrandHeading from '~/client/shared/AuthBrandHeading';
import Translate from '~/client/shared/i18n/Translate';
import Stack from '~/client/shared/Stack';
import FormLogin from './FormLogin';
import SocialLogin from './SocialLogin';
import BrandThemeProvider from '../../Shared/BrandThemeProvider';

const AbTest = dynamic(() => import('./AbTest'));

type Props = {
  isPopover?: boolean;
  onSuccess?: () => void;
};

const LoginBody: FC<Props> = ({ onSuccess, isPopover = false }) => {
  useLogin();
  const { showVariant1, showVariant2 } = useAbTest({
    name: 'oneCtaRegistration',
    device: 'desktop',
  });
  const showSignIn = showVariant1 || showVariant2;
  return (
    <Stack alignContent="start" gridGap={rem(24)}>
      {showSignIn ? <AbTest /> : <AuthBrandHeading id="profile.login" />}
      {isPopover && (
        <Stack padding={`${rem(20)} 0`}>
          <Text>
            <Translate id="casino.please_login_to_play_for_real" />
          </Text>
        </Stack>
      )}
      <SocialLogin />
      <KeylineText>
        <Translate id="general.or" />
      </KeylineText>
      <Stack space="small">
        <BrandThemeProvider hasBombayClubTheme={isPopover}>
          <FormLogin isPopover={isPopover} onSuccess={onSuccess} />
        </BrandThemeProvider>
      </Stack>
    </Stack>
  );
};

const useLogin = () => {
  const [message, , removeMsg] = useQueryParam('msg');
  const { showToast } = useToastContext();
  useDidMount(() => {
    if (message) {
      showToast({ message, variant: 'info', timeout: 7000 });
      removeMsg();
    }
  });
};

export default LoginBody;

import useRouter from '~/client/hooks/router/useRouter';

const useLink = (pathname: string) => {
  const { query } = useRouter();

  const href = {
    pathname: `/${pathname}`,
    query: query.redirect ? { redirect: query.redirect } : {},
  };

  return { href };
};

export default useLink;

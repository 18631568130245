import { Text } from '@heathmont/moon-core';
import { useRouter } from 'next/navigation';
import useLink from '~/client/components/Auth/Shared/useLink';
import Translate from '~/client/shared/i18n/Translate';
import NextLink from '~/client/shared/NextLink';
import useAccountPopoverStore from '~/client/store/useAccountPopoverStore';
import CreateAccountLinkBody from './CreateAccountLinkBody';
type Props = {
  isPopover?: boolean;
};
const CreateAccountLink: FC<Props> = ({ isPopover }) => {
  const { href } = useLink('register');
  const { showPopover } = useAccountPopoverStore();
  const router = useRouter();
  if (isPopover) {
    return (
      <CreateAccountLinkBody>
        <div
          className="cursor-pointer text-piccolo"
          onClick={() => showPopover('signup')}
        >
          <Translate id="profile.create_account" />
        </div>
      </CreateAccountLinkBody>
    );
  }
  return (
    <CreateAccountLinkBody>
      <NextLink href={href} onTouchEnd={() => router.replace(href.pathname)}>
        <Text size={14} color="piccolo.100">
          <Translate id="profile.create_account" />
        </Text>
      </NextLink>
    </CreateAccountLinkBody>
  );
};

export default CreateAccountLink;

import { forwardRef } from 'react';
import type { TextInputProps } from '@heathmont/moon-core';
import { Input, Label } from '@heathmont/moon-core-tw';
import useInputProps from './hooks/useInputProps';
import StyledTextInput from '../styles/TextInput';

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const { newInputSize, isOldInput, size, label, name, isError, inputProps } =
    useInputProps(props);

  if (isOldInput) {
    // TODO: replace with a Group component from Moon
    return <StyledTextInput size={size} ref={ref} {...props} />;
  }
  return (
    <div>
      <Label className="text-trunks" htmlFor={name} size={newInputSize}>
        {label}
      </Label>
      <Input
        error={isError}
        name={name}
        id={name}
        ref={ref}
        size={newInputSize}
        {...inputProps}
      />
    </div>
  );
});

export default TextInput;

import asyncTimeout from '~/shared/utils/asyncTimeout';
import CommsCommands from '@/client/helpers/CommsCommands';

type Args = { opts: Record<string, unknown> };

const OPEN_CHAT_DELAY = 50;

const updateAndOpenChat = async ({ opts }: Args) => {
  CommsCommands.update({
    args: opts,
  });

  const chatCloseButton = global?.document?.querySelector?.(
    '[data-name="close-chat"]',
  );
  if (chatCloseButton) {
    await asyncTimeout(OPEN_CHAT_DELAY);
    CommsCommands.show({});
  }
};

export default updateAndOpenChat;

import type { HTMLAttributes } from 'react';
import { FormError as MoonFormError } from '@heathmont/moon-components';
import useTranslation from '~/client/hooks/i18n/useTranslation';
import useSite from '~/client/hooks/useSite';

type Props = { message?: string } & HTMLAttributes<HTMLParagraphElement>;

const FormError: FC<Props> = ({ message = '', ...htmlProps }) => {
  const { siteConfig } = useSite();
  const formatMessage = message?.startsWith('error.')
    ? message.replace('error.', 'errors.')
    : message;
  const domainTranslation = useTranslation(formatMessage).replace(
    'bitcasino.io',
    siteConfig.domain,
  );
  return <MoonFormError {...htmlProps}>{domainTranslation}</MoonFormError>;
};

export default FormError;

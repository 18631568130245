import { forwardRef } from 'react';
import CheckboxPure from '@heathmont/moon-base-tw/lib/checkbox/Checkbox';
import { Checkbox } from '@heathmont/moon-core-tw';
import { ColorProps } from '@heathmont/moon-themes';

type Props = {
  color?: ColorProps;
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  onClick?: () => void;
};

const CustomCheckbox = forwardRef<HTMLInputElement, Props>((props, ref) => (
  <div className="[&>label]:text-moon-12 flex justify-between items-center [&>span]:pr-4">
    {props.onClick ? (
      <CheckboxPure {...props} ref={ref} className="text-goku" />
    ) : (
      <Checkbox {...props} ref={ref} className="text-goku" />
    )}
  </div>
));

export default CustomCheckbox;

import Stack from '~/client/shared/Stack';
import CreateAccountLink from './CreateAccountLink';
import ProtectedByRecaptcha from '../../ProtectedByRecaptcha';
import ForgotPasswordLink from '../../Shared/ForgotPasswordLink';
type Props = {
  isPopover?: boolean;
};
const LoginBottomLinks: FC<Props> = ({ isPopover }) => (
  <Stack space="small" alignContent="start">
    <ForgotPasswordLink />
    <CreateAccountLink isPopover={isPopover} />
    <ProtectedByRecaptcha />
  </Stack>
);

export default LoginBottomLinks;

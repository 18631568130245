import { Text } from '@heathmont/moon-core';
import useFeature from '~/client/hooks/feature/useFeature';
import useTranslation from '~/client/hooks/i18n/useTranslation';
import Stack from '~/client/shared/Stack';

const ProtectedByRecaptcha: FC = () => {
  const { recaptchaMessage, hideMessage } = useProtectedByRecaptcha();
  if (hideMessage) {
    return null;
  }

  return (
    <Stack space="small" alignContent="end">
      <Text
        size={12}
        color="trunks.100"
        dangerouslySetInnerHTML={{ __html: recaptchaMessage }}
      />
    </Stack>
  );
};

const useProtectedByRecaptcha = () => {
  const recaptchaMessage = useTranslation('general.protected_by_recaptcha');
  const isRecaptchaV3Enabled = useFeature('isRecaptchaV3Enabled');
  return { recaptchaMessage, hideMessage: !isRecaptchaV3Enabled };
};

export default ProtectedByRecaptcha;

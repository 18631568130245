/* eslint-disable immutable/no-mutation */
import { useMemo } from 'react';
import useCid from '~/client/hooks/cookies/marketing/useCid';
import useRefAff from '~/client/hooks/cookies/marketing/useRefAff';
import useRefCode from '~/client/hooks/cookies/marketing/useRefCode';
import useHasGroupTags from '~/client/hooks/feature/useGroupTags';
import useTag from '~/client/hooks/feature/useTag';
import useRouter from '~/client/hooks/router/useRouter';
import getToken from '~/shared/token/getToken';
import { useGtmUserDataQuery } from './graphql/GtmUserData.generated';

type Args = { authToken?: string };

const useUpdateGtmUserData = () => {
  const { locale } = useRouter();
  const [refAff] = useRefAff();
  const [cid] = useCid();
  const refCode = useRefCode();
  const isVip = useHasGroupTags();
  const isInsider = useTag('Insider');

  const { refetch } = useGtmUserDataQuery({
    fetchPolicy: 'network-only',
    skip: true,
  });

  const marketing = useMemo(
    () => ({
      refCode,
      refAff,
      cid,
    }),
    [cid, refAff, refCode],
  );

  const anonymousUserData = useMemo(
    () => ({
      locale,
      marketing,
      isVip: false,
      isInsider: false,
      isLoggedIn: false,
      user: {
        userId: undefined,
        username: undefined,
        email: undefined,
        affiliateID: undefined,
      },
    }),
    [locale, marketing],
  );

  const updateGtmUserData = async ({ authToken }: Args = {}) => {
    const token = authToken ?? getToken();

    const { data } = (await refetch()) ?? {};

    const { userManagement } = data ?? {};
    const { _id, username, email, affiliateID } =
      userManagement?.currentProfile ?? {};

    if (!token || !userManagement?.currentProfile) {
      global.GTM = anonymousUserData;
      return;
    }

    const gtmData = {
      locale,
      marketing,
      isVip,
      isInsider,
      isLoggedIn: true,
      user: {
        username,
        email,
        userId: _id,
        affiliateID,
      },
    };

    global.GTM = gtmData;
  };

  return { updateGtmUserData };
};

export default useUpdateGtmUserData;

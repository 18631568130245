import { createEvent, createStore } from 'effector';
import { useUnit } from 'effector-react';

type ActivePopover = 'login' | 'signup' | 'socials';

type AccountPopoverStore = {
  activePopover?: ActivePopover;
  isDismissed?: boolean;
  isDisplayed?: boolean;
};

const showPopover = createEvent<ActivePopover>();
const dismissPopover = createEvent<boolean>();
const acountPopoverStore = createStore<AccountPopoverStore>({
  isDisplayed: false,
});

acountPopoverStore.on(showPopover, (store, activePopover) => ({
  ...store,
  activePopover,
  isDismissed: false,
  isDisplayed: true,
}));

acountPopoverStore.on(dismissPopover, (_, isDismissed) => ({
  isDismissed,
  isDisplayed: false,
}));

const useAccountPopoverStore = () => {
  const store = useUnit(acountPopoverStore);
  return { ...store, dismissPopover, showPopover };
};

export default useAccountPopoverStore;

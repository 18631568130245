import LocalStorage from '~/shared/utils/storage/LocalStorage';

const KEY = 'social_auth_type';

const authType = {
  get: () => LocalStorage.getItem(KEY) as AuthType,
  set: (value: AuthType) => LocalStorage.setItem(KEY, value),
  remove: () => LocalStorage.removeItem(KEY),
};

export default authType;

import dynamic from 'next/dynamic';
import type Props from './Props';

const Auth0Callback = dynamic(() => import('./Callbacks/Auth0Callback'), {
  ssr: false,
});
const CognitoCallback = dynamic(() => import('./Callbacks/CognitoCallback'), {
  ssr: false,
});
const SocialAuthLogin = dynamic(() => import('./SocialAuthLogin'), {
  ssr: false,
});

const SocialAuth: FC<Props> = ({ module }) => {
  switch (module) {
    case 'auth0Callback':
      return <Auth0Callback />;
    case 'cognitoCallback':
      return <CognitoCallback />;
    case 'login':
      return <SocialAuthLogin />;
    default:
      return <div />;
  }
};

export default SocialAuth;

import styled, { css } from 'styled-components';
import Stack from '../Stack';

type Props = {
  inline?: boolean;
  flexWrap?: string;
  fullWidth?: boolean;
  center?: boolean;
};

const FlexStack = styled(Stack)<Props>`
  && {
    display: flex;
    flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
    ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
    ${({ fullWidth }) => fullWidth && fullWidthStyle}
    ${({ center }) => center && centerStyle}
  }
`;

const fullWidthStyle = css`
  width: 100%;
`;

const centerStyle = css`
  align-items: center;
  justify-content: center;
`;

export default FlexStack;

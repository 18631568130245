import { forwardRef } from 'react';
import type { TextInputProps } from '@heathmont/moon-core';
import { Form } from '@heathmont/moon-core-tw';
import useTranslation from '~/client/hooks/i18n/useTranslation';
import TextInput from './TextInput';
import FormError from '../FormError';

type Props = TextInputProps & {
  error?: string;
  isSharpTopSide?: boolean;
  skipErrorMessage?: boolean;
  isErrorTop?: boolean;
  isSharpBottomSide?: boolean;
};

const FormInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    error = '',
    label = '' as string,
    placeholder = '',
    type = '',
    name = '',
    inputSize = 'medium',
    isSharpTopSide = false,
    isSharpBottomSide = false,
    isErrorTop = false,
    skipErrorMessage,
    ...inputProps
  } = props;

  const labelTranslation = useTranslation(label as string);
  const placeholderTranslation = useTranslation(placeholder);
  const skipError = skipErrorMessage || !error;

  return (
    <Form.Item>
      {!skipError && isErrorTop && (
        <FormError message={error} data-testid={`${name}-form-error`} />
      )}
      <TextInput
        label={labelTranslation}
        ref={ref}
        type={type}
        isError={!!error}
        isSharpTopSide={isSharpTopSide}
        isSharpBottomSide={isSharpBottomSide}
        inputSize={inputSize}
        placeholder={placeholderTranslation}
        name={name}
        {...inputProps}
      />
      {!skipError && !isErrorTop && (
        <FormError message={error} data-testid={`${name}-form-error`} />
      )}
    </Form.Item>
  );
});

export default FormInput;

import React from 'react';
import { Inline } from '@heathmont/moon-components';
import { Text } from '@heathmont/moon-core';
import Translate from '~/client/shared/i18n/Translate';

const CreateAccountLinkBody: FC = ({ children }) => (
  <Inline space="small">
    <Text size={14} color="trunks.100">
      <Translate id="profile.dont_have_an_account" />
    </Text>
    {children}
  </Inline>
);

export default CreateAccountLinkBody;

/* eslint-disable immutable/no-mutation */
import { useEffect, useRef } from 'react';
import useFeature from '~/client/hooks/feature/useFeature';
import useRouter from '~/client/hooks/router/useRouter';
import useAgentData from '~/client/hooks/useAgentData';
import useLanguage from '~/client/hooks/useLanguage';
import useCurrentUser from '~/client/hooks/user/useCurrentUser';
import useIsLoggedIn from '~/client/hooks/user/useIsLoggedIn';
import useSite from '~/client/hooks/useSite';
import protoChatId from '../protoChatId';
import ProtoCommands from '../utils/ProtoCommands';
import useCashierStore from '@/client/hooks/useCashierStore';

const transformWindow = (shift: number) => {
  const frame = document?.getElementById(protoChatId);

  frame?.style.setProperty('transform', `translate(0, ${shift}px)`);
};

const useProto = () => {
  const cashierOpen = useRef(false);
  const isLoggedIn = useIsLoggedIn();
  const { code } = useLanguage();
  const { profile } = useCurrentUser();
  const { siteConfig } = useSite();
  const { isOpen } = useCashierStore();
  const isProtoEnabled =
    useFeature('isProtoEnabled') && siteConfig.protoConfig?.gwId !== undefined;
  const { isMobile } = useAgentData();
  const { asPath } = useRouter();

  useEffect(() => {
    const protoEL = document.getElementById(protoChatId);

    if (isOpen) {
      protoEL?.style.setProperty('z-index', '10001');
    } else {
      protoEL?.style.setProperty('z-index', '49');
    }

    cashierOpen.current = isOpen;

    const commsBtn = document?.querySelector('#comms');

    if (isProtoEnabled) {
      commsBtn?.remove();
    }
  }, [isOpen, isProtoEnabled]);

  useEffect(() => {
    if (!isLoggedIn) {
      ProtoCommands.hide();
    }
  }, [isLoggedIn]);

  const initProto = () => {
    if (siteConfig.protoConfig?.gwId === undefined) {
      return;
    }

    ProtoCommands.init({
      args: {
        gwId: siteConfig.protoConfig.gwId,
        humanUid: profile?._id,
        lang: code === 'zh' ? 'zh_CN' : code,
      },
      onComplete: () => {
        const commsBtn = document?.querySelector('#comms');

        commsBtn?.remove();

        if (asPath === '/chat') {
          ProtoCommands.open();
        }

        ProtoCommands.show();

        if (isMobile) {
          transformWindow(-60);
        }

        if (Proto) {
          const protoEL = document.getElementById(protoChatId);

          protoEL?.style.setProperty('z-index', '49');

          if (code === 'ar') {
            protoEL?.style.setProperty('inset', 'auto auto 0 0');
          }

          Proto.onWindowClosed = function () {
            isMobile && transformWindow(-60);

            if (protoEL && cashierOpen.current) {
              // eslint-disable-next-line immutable/no-mutation
              protoEL.style.display = 'none';
            }
          };
          Proto.onWindowOpened = function () {
            if (protoEL && cashierOpen.current) {
              // eslint-disable-next-line immutable/no-mutation
              protoEL.style.display = 'block';
            }

            isMobile && transformWindow(0);
          };
        }
      },
    });
  };

  return { isProtoEnabled, initProto };
};

export default useProto;

import { forwardRef } from 'react';
import { FormItem } from '@heathmont/moon-components';
import type { ColorProps } from '@heathmont/moon-themes';
import CustomCheckbox from './styles/CustomCheckbox';
import FormError from '../FormError';

type Props = {
  label?: string;
  checked?: boolean;
  error?: string;
  disabled?: boolean;
  color?: ColorProps;
  onClick?: () => void;
};

const FormCheckbox = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { error = '' } = props;
  return (
    <FormItem>
      <CustomCheckbox ref={ref} {...props} />
      {error && <FormError className="relative bottom-2" message={error} />}
    </FormItem>
  );
});

export default FormCheckbox;

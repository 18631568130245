import usePreviousPath from '~/client/hooks/router/usePreviousPath';
const useIsBombayClubTheme = () => {
  const { getPreviousPath } = usePreviousPath();
  const path = getPreviousPath();
  const isBombayClubTheme = path?.includes('/bombayclub');
  return {
    isBombayClubTheme,
  };
};

export default useIsBombayClubTheme;

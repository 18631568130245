import { useContext } from 'react';
import GameContext from './GameContext';

const useGameContext = () => {
  const context = useContext(GameContext);
  if (context === undefined) {
    throw new Error(`useGameContext must be used within GameContextProvider`);
  }
  return context;
};

export default useGameContext;

import { useCallback } from 'react';
import useGameContext from '~/client/components/Game/context/Game/useGameContext';
import useTranslation from '~/client/hooks/i18n/useTranslation';
import useRedirect from '~/client/hooks/router/useRedirect';
import useRouter from '~/client/hooks/router/useRouter';
import useCurrentUser from '~/client/hooks/user/useCurrentUser';
import useSite from '~/client/hooks/useSite';
import useParseExternalHref from '~/client/shared/NextLink/useNextLink/useParseExternalHref';
import useAccountPopoverStore from '~/client/store/useAccountPopoverStore';
import sendEventToUmami from '@/client/helpers/trackEvent/sendEventToUmami';

const useFunModeToggle = () => {
  const { showPopover } = useAccountPopoverStore();
  const { user } = useCurrentUser();
  const { isBridgeSite } = useSite();
  const { replace, asPath } = useRouter();
  const { isFunMode, setFunMode } = useGameContext();
  const leftLabel = useTranslation('game.fun_mode');
  const rightLabel = useTranslation('game.real_mode');
  const { parseExternalHref } = useParseExternalHref();
  const { redirect } = useRedirect();

  const handleChange = useCallback(
    (checked: boolean) => {
      if (!user) {
        if (isBridgeSite) {
          replace(`${asPath}?c=home`);
          redirect(parseExternalHref('/register'));
          return;
        }
        showPopover('signup');
        setFunMode(checked);
        setTimeout(() => {
          setFunMode(!checked);
        }, 0);
        return;
      }
      setFunMode(checked);

      sendEventToUmami(
        {
          handle: 'game-handle',
          newMode: checked ? 'fun' : 'real',
        },
        'toggleFunOrRealMode',
      );
    },
    [
      user,
      setFunMode,
      showPopover,
      isBridgeSite,
      parseExternalHref,
      replace,
      redirect,
      asPath,
    ],
  );

  return { isFunMode, handleChange, leftLabel, rightLabel };
};

export default useFunModeToggle;

import type { RefObject } from 'react';
import { FormItem } from '@heathmont/moon-components';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from 'react-hook-form';
import type Reaptcha from 'reaptcha';
import ReCAPTCHA from '../../ReCAPTCHA';
import FormError from '../FormError';

type Props<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  error?: string;
  rules?: RegisterOptions;
  recaptchaRef?: RefObject<Reaptcha>;
};

const FormReCAPTCHA = <T extends FieldValues>({
  name,
  control,
  rules,
  error,
  recaptchaRef,
}: Props<T>) => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    render={({ field }) => (
      <FormItem data-testid="recaptcha">
        <ReCAPTCHA onVerify={field?.onChange} recaptchaRef={recaptchaRef} />
        <FormError message={error} />
      </FormItem>
    )}
  />
);

export default FormReCAPTCHA;

import useRouter from '~/client/hooks/router/useRouter';
import SessionStorage from '~/shared/utils/storage/SessionStorage';

const searchUrlPattern = new RegExp('^(/search)$', 'g');
const menuUrlPattern = new RegExp('^(.*(\\?modal=menu))$', 'g');

const usePreviousPath = () => {
  const { asPath } = useRouter();
  const isIgnoredPath = !!(
    asPath.match(searchUrlPattern) || asPath.match(menuUrlPattern)
  );

  const savePreviousPath = () => {
    if (!isIgnoredPath) {
      SessionStorage.setItem('previousPath', asPath);
    }
  };

  const getPreviousPath = (): string | undefined =>
    SessionStorage.getItem('previousPath', '/');

  return {
    savePreviousPath,
    getPreviousPath,
  };
};

export default usePreviousPath;

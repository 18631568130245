type Params = {
  args: {
    gwId: string;
    lang?: string;
    humanUid?: string;
  };
  onComplete?: () => void;
};

type ProtoCommandsType = {
  show: () => void;
  hide: () => void;
  init: (params: Params) => void;
  open: () => void;
  close: () => void;
  setLanguage: (langCode: string) => void;
  setHumanUid: (id: string) => void;
};

const ProtoCommands: ProtoCommandsType = {
  show: () => global.Proto?.show(),
  hide: () => global.Proto?.hide(),
  init: ({ args, onComplete }) => {
    global.Proto?.init(args, onComplete);
  },
  open: () => {
    ProtoCommands.show();
    global.Proto?.openWindow();
  },
  close: () => global.Proto?.closeWindow(),
  setLanguage: (langCode) => global.Proto?.setLanguage(langCode),
  setHumanUid: (id) => global.Proto?.setHumanUid(id),
};

export default ProtoCommands;

'use client';
import { rem } from '@heathmont/moon-utils';
import Stack from '~/client/shared/Stack';
import LoginBody from './LoginBody';
import LoginBottomLinks from './LoginBottomLinks';
import BrandThemeProvider from '../Shared/BrandThemeProvider';

type Props = {
  isPopover?: boolean;
  onSuccess?: () => void;
};

const Login: FC<Props> = ({ onSuccess, isPopover = false }) => (
  <Stack templateRows="minmax(1px,1fr) minmax(1px,120px)" gridGap={rem(24)}>
    <LoginBody isPopover={isPopover} onSuccess={onSuccess} />
    <BrandThemeProvider hasBombayClubTheme={isPopover}>
      <LoginBottomLinks isPopover={isPopover} />
    </BrandThemeProvider>
  </Stack>
);

export default Login;

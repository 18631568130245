import SocialAuth from '~/client/features/SocialAuth';
import useSocialAuthLocale from '~/client/features/SocialAuth/hooks/useSocialAuthLocale';
import SocialLoginError from './SocialLoginError';

const SocialLogin: FC = () => {
  const [, setLocale] = useSocialAuthLocale();
  setLocale();
  return (
    <>
      <SocialAuth module="login" />
      <SocialLoginError />
    </>
  );
};

export default SocialLogin;

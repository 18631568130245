import { useState } from 'react';
import useCookie from '~/shared/hooks/useCookie';
import maskPhoneNumber from '../components/2faManaging/helpers/maskPhoneNumber';

const usePhoneNumberHint = () => {
  const [phoneHint, setPhoneHint] = useState<string>('');
  const [cookie, setCookie] = useCookie('phoneNumberHint', phoneHint);
  const setPhoneNumberHint = (errors: GenericErrorType[]) => {
    const sms2faError = errors?.find(
      (error) => error.message === 'SMS_2FA_IS_REQUIRED',
    );
    const phoneNumberHintParam = sms2faError?.params?.find(
      (param) => param.name === 'phoneNumberHint',
    );
    if (phoneNumberHintParam?.value) {
      const phoneNumber = maskPhoneNumber(phoneNumberHintParam?.value);
      setPhoneHint(phoneNumber);
      setCookie(phoneNumber);
    }
  };

  return { setPhoneNumberHint, phoneHint: phoneHint || cookie };
};

export default usePhoneNumberHint;

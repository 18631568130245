import { Text, TextSize } from '@heathmont/moon-core';
import type { ColorNames } from '@heathmont/moon-themes';
import { useRouter } from 'next/navigation';
import styled from 'styled-components';
import Translate from '~/client/shared/i18n/Translate';
import NextLink from '~/client/shared/NextLink';

type Props = {
  isUnderline?: boolean;
  color?: ColorNames;
  size?: TextSize;
  fontWeight?: number;
};

const CustomText = styled(Text)<Props>`
  && {
    ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
  }
`;

const ForgotPasswordLink: FC<Props> = ({
  isUnderline,
  color = 'piccolo.100',
  size = 16,
  fontWeight,
}) => {
  const router = useRouter();
  return (
    <NextLink
      onTouchEnd={() => router.push('/forgot-password/reset')}
      href="/forgot-password/reset"
    >
      <CustomText
        fontWeight={fontWeight}
        color={color}
        size={size}
        isUnderline={isUnderline}
      >
        <Translate id="profile.forgot_password" />
      </CustomText>
    </NextLink>
  );
};

export default ForgotPasswordLink;

import isStorageAvailable from './isStorageAvailable';

const LocalStorage = (() => ({
  getItem: (name: string) => {
    if (!isStorageAvailable('localStorage')) {
      return '';
    }
    return JSON.parse(localStorage.getItem(name) ?? '{}');
  },
  setItem: (name: string, value: unknown) => {
    if (!isStorageAvailable('localStorage')) {
      return;
    }
    localStorage.setItem(name, JSON.stringify(value));
  },
  removeItem: (name: string) => {
    if (!isStorageAvailable('localStorage')) {
      return;
    }
    localStorage.removeItem(name);
  },
}))();

export default LocalStorage;
